<template>
  <div id="app">
    <Home msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
import Home from "./components/Home.vue";

export default {
  name: "App",
  components: {
    Home,
  },
};
</script>

<style>
/*app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  */

@font-face {
  font-family: "Cera Pro";
  src: local("Cera Pro"),
   url("../src/assets/fonts/cera-pro-cufonfonts/Cera Pro Regular.otf") format("truetype");
}


</style>
