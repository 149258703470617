<template>
  <div class="main-content full-block">
    <div class="container">
      <nav class="main-nav" id="menubar">
        <ul class="main-links">
          <li class="logo"><a href="index.html"><img src="../assets/img/albcodenew.png"></a></li>
          <li class="item"><a href="index.html">Home</a></li>
          <li class="item"><a href="#aboutus">About Us</a></li>
          <li class="item"><a href="#services">Services</a></li>
          <li class="item button"><a href="#contact">Contact</a></li>
          <li class="toggle">
            <a href="#"><span class="bars"></span></a>
          </li>
        </ul>
      </nav>
    </div>

    <div class="hero-banner full-block">
      <div class="container hero-container">
        <div class="main-img">

        </div>
        <div class="main-info">
          <div class="main-info__title">
            <h1>DIGITALIZE YOUR NEEDS</h1>
          </div>
          <div class="main-info__text">
            <p>
             We transform businesses with powerful and adaptable digital solutions that satisfy the needs of today and unlock the opportunities of tomorrow
            </p>
          </div>
          <div class="main-info__buttons">
            <a class="services btn" v-scroll-to="{el: '#services' , duration: 2 * 1000}">Our Services</a>
            <a class="contact-us btn " v-scroll-to="{el: '#contact'}">Contact Us</a>
          </div>
        </div>
      </div>
    </div>

    <div id="aboutus">
      <div class="about-us full-block">
        <div class="container about-container">
          <div class="about-us--img">
            <img src="../assets/img/Project_158-03-removebg-preview.png" />
          </div>
          <div class="about-us--info">
            <div class="about-us__title">
              <h3>About AlbCode</h3>
              <h2>Software Development Company</h2>
            </div>
            <div class="about-us__text">
              <p>
                At AlbCode, we tend to solve your requirements in the simplest way with the most advanced technologies applicable nowadays. With an experienced development team offering the best solutions to our clients 
                we manage to fulfill all your needs.
              </p>
            </div>
            <div class="about-us__btn">
              <a class="aboutus btn" v-scroll-to="{el: '#services'}">Services</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="services">
      <div class="software-services full-block">
        <div class="container services-container">
          <div class="services--info">
            <div class="services__title">
              <h3>AlbCode Software</h3>
              <h2>Software Services</h2>
            </div>
          </div>

          <div class="services-containers">
            <div class="wrapper">
              <div class="web-dev item1 items">
                <div class="android icon"></div>
                <div class="android title">Web Development</div>
                <div class="android text">
                  Create complex Web Applications, ensure reliable development integration. 
                </div>
              </div>
              <div class="android item2 items">
                <div class="android icon"></div>
                <div class="android title">Mobile App Development</div>
                <div class="android text">
                  Create an impactful mobile app that fits your brand and industry within a shorter time frame.
                </div>
              </div>
              <div class="online-booking item3 items">
                <div class="android icon"></div>
                <div class="android title">Blockchain Technology</div>
                <div class="android text">
                 Introduce the highest level of security and automate your operations with our blockchain solutions.
                </div>
              </div>
              <div class="ecommerce-services item4 items">
                <div class="android icon"></div>
                <div class="android title">eCommerce Services</div>
                <div class="android text">
                  We provide an engaging and appealing website design influence your eCommerce venture and stand out from the rest. 
                </div>
              </div>
              <div class="network-support item5 items">
                <div class="android icon"></div>
                <div class="android title">Dedicated Development Team</div>
                <div class="android text">
                 Hire a loyal software development team with niche skills and deep expertise.
                </div>
              </div>
              <div class="cloud-solutions item6 items">
                <div class="android icon"></div>
                <div class="android title">IT Consulting</div>
                <div class="android text">
                  Turn to our experts to perform comprehensive, mulit-stage testing and auditing of your software.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="contact">
      <div class="footer">
        <div class="container footer-container">
          <div class="links-container">

           
            <div class="quick-links">
              <div class="container">
                <h4>Quick Links</h4>

                <ul>
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="#">About Us</a>
                  </li>
                  <li>
                    <a href="#">Services</a>
                  </li>
                </ul>
              </div>
            </div>
          
            <div class="social-medias">
              <h4>Follow Us</h4>

               <linkedin :url="url" scale="1"></linkedin>
            </div>
               <div class="contact">
              <h4>Contact Info</h4>
              <div class="map-resopnsive">
             <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2934.107177683103!2d21.145041915612854!3d42.65908412415728!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549ef9c3f1c017%3A0x7a655ac4fb5a12fa!2sAhmet%20Krasniqi%2C%20Prishtin%C3%AB!5e0!3m2!1sen!2s!4v1637833945373!5m2!1sen!2s"
              width="300" height="130" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
              </div>
            </div>
          </div>
          <div class="copyright">
            <p>© 2021 All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <h1>{{ msg }}</h1>
    <p>
      For a guide and recipes on how to configure / customize this project,<br>
      check out the
      <a href="https://cli.vuejs.org" target="_blank" rel="noopener">vue-cli documentation</a>.
    </p>
    <h3>Installed CLI Plugins</h3>
    <ul>
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-babel" target="_blank" rel="noopener">babel</a></li> 
      <li><a href="https://github.com/vuejs/vue-cli/tree/dev/packages/%40vue/cli-plugin-eslint" target="_blank" rel="noopener">eslint</a></li>
    </ul>
    <h3>Essential Links</h3>
    <ul>
      <li><a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a></li>
      <li><a href="https://forum.vuejs.org" target="_blank" rel="noopener">Forum</a></li>
      <li><a href="https://chat.vuejs.org" target="_blank" rel="noopener">Community Chat</a></li>
      <li><a href="https://twitter.com/vuejs" target="_blank" rel="noopener">Twitter</a></li>
      <li><a href="https://news.vuejs.org" target="_blank" rel="noopener">News</a></li>
    </ul>
    <h3>Ecosystem</h3>
    <ul>
      <li><a href="https://router.vuejs.org" target="_blank" rel="noopener">vue-router</a></li>
      <li><a href="https://vuex.vuejs.org" target="_blank" rel="noopener">vuex</a></li>
      <li><a href="https://github.com/vuejs/vue-devtools#vue-devtools" target="_blank" rel="noopener">vue-devtools</a></li>
      <li><a href="https://vue-loader.vuejs.org" target="_blank" rel="noopener">vue-loader</a></li>
      <li><a href="https://github.com/vuejs/awesome-vue" target="_blank" rel="noopener">awesome-vue</a></li>
    </ul>-->
  </div>
</template>

<script>
import { Linkedin } from 'vue-socialmedia-share';

export default {
  name: "Home",
  props: {
    msg: String,
  },
  components: {
    Linkedin
  }
};


</script>

<style>
  @import '../assets/styles/style.css';
  </style>

